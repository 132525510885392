<template>
  <tfoms-panel
    v-if="data"
    title="Сотрудники"
    :caption="'Не известных сотрудников - ' + NonameEmployeePercent + '%'"
    small
    v-bind="$attrs"
    :labels="[selectPartiton ? selectPartiton.Name : '', ...labels]"
  >
    <div
      class="analysis-tfoms_employee-table analysis-tfoms__table analysis-tfoms__base-table"
    >
      <base-table-full
        v-if="data && data.length >= 0"
        :dataSource="data"
        :headers="this['header' + type]"
        hide-default-footer
        notShowSelect
        @click:row="
          $emit('input', value && value.Id === $event.Id ? null : $event)
        "
        :sort-by="sortBy[type - 1]"
        :sort-desc="[true]"
        :item-class="
          (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
        "
        small
        :exclude-settings-actions="['table', 'group']"
      >
      </base-table-full>
    </div>
  </tfoms-panel>
</template>
<script>
import BaseTableFull from "@/components/base/BaseTableFull";

import TFormService from "@/services/TFormService";
export default {
  components: {
    BaseTableFull,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
        unit: null,
      }),
    },
    queryParams: {
      default: () => ({
        params: {},
      }),
    },
    detailParams: {
      default: () => ({
        params: {},
      }),
    },
  },
  props: {
    selectPartiton: {
      type: Object,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    value: { type: Object, default: null },
    type: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: [
        "VolumeValue",
        "NonPaymentValue",
        "VolumeValue",
        "FinancialValue",
        "NonPaymentValue",
      ],
      data: null,
      lastData: null,
      NonameEmployeePercent: 0,
      header1: [
        { text: "Сотрудник", value: "Name" },
        {
          text: "Объемы",
          value: "VolumeValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header2: [
        { text: "Сотрудник", value: "Name" },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Штрафы",
          value: "FineValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header3: [
        { text: "Сотрудник", value: "Name" },
        {
          text: "Объемы",
          value: "VolumeValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Фин. обеспечение",
          value: "FinancialValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Штрафы",
          value: "FineValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header4: [
        { text: "Сотрудник", value: "Name" },
        {
          text: "Сумма",
          value: "FinancialValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
      header5: [
        { text: "Сотрудник", value: "Name" },
        {
          text: "Неоплата",
          value: "NonPaymentValue",
          align: "center",
          dataType: "string",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
      ],
    };
  },
  watch: {
    selectPartiton(val) {
      this.init();
    },
    items: {
      immediate: true,

      handler(val) {
        this.NonameEmployeePercent = val.NonameEmployeePercent;
        this.init(val.Employees);
      },
    },
  },
  methods: {
    getRub(num) {
      if (this.provideUnits.rub.id === 0) {
        return num || 0;
      } else if (this.provideUnits.rub.id === 1) {
        const t = num / 1000;
        return parseFloat(t.toFixed(2));
      }
    },
    getUnit(num) {
      if (this.provideUnits.unit.id === 0) {
        return num || 0;
      } else if (this.provideUnits.unit.id === 1) {
        return Math.round(num / 1000);
      }
    },
    normolize(data) {
      for (let i = 0; i < data.Employees.length; i++) {
        const item = data.Employees[i];
        item.FinancialValue = this.getRub(item.FinancialValue);
        item.FineValue = this.getRub(item.FineValue);
        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
        item.VolumeValue = this.getUnit(item.VolumeValue);
      }
      return data;
    },
    async init(items) {
      if (items) {
        this.data = this.normolize(this._.cloneDeep(items));
      } else {
        this.data = this.normolize(
          (
            await TFormService.tfoms1.getEmployeeValues({
              ...this.queryParams.params,
              treeId: this.selectPartiton?.TreeId,
              reasonId: this.detailParams.params?.reasonId,
            })
          ).data
        );

        this.NonameEmployeePercent = this.data.NonameEmployeePercent;
        this.data = this.data.Employees;
      }
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms_employee-table {
  .v-data-table table tr td {
    cursor: pointer;
  }
  .v-data-table__wrapper {
    max-height: 500px;
  }
}
</style>
